* {
    margin: auto;
    color: #b4c7d9;
}

html {
    background-color:#012e57;
    max-width: 1300px;
}

a {
    text-decoration: none;
    padding-top:5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.App {
    font-family: 'JetBrains Mono', monospace;
    font-size: 16pt;
}

.Header {
    /* font-family: 'JetBrains Mono', monospace; */
    font-family: Verdana, sans-serif;
    font-size: 60pt;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 80px;
    line-height: 100%;
    box-shadow: 0 0 5px #000;
    max-width:60%;
}

.Footer {
    text-align: center;
    margin-top: 40px;
}

#myName {
    text-shadow: 0 0 2px black, 0 0 6px black;
}

#MainBody {
    text-align: center;
    white-space: pre-line;
}

#navbar {
    font-size: 16pt;
    max-width: 800px;
}

#navbarItems {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    list-style-type: none;
}

#navbarItems li {
    padding-left: 5px;
    padding-right: 5px;
}

#navbarItems a:visited {
    color: #b4c7d9;
}

#Resume {
    text-align: left;
    border-style: solid;
    padding: 25px;
    border-width: 3px;
}

#Resume h1 {
    text-align: center;
    margin-bottom: 15px;
}


#Resume h2 {
    text-align: center;
    margin: 5px 0px 5px 0px;
    border-bottom: 2px solid whitesmoke;
}

#Resume h3 {
    margin: 5px 0px 5px 0px;
}

.resumeDate {
    text-decoration: underline;
}
.resumeCompany {
    padding-top: 30px;
    font-size: 15pt;
    color: #a4b7c9;
}

.resumeTitle {
    font-weight: bold;
}

.resume-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    gap: 80px;
}

#resume-col1 {
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: 100%
}

#resume-col2 {
    margin-top: 0px;
    flex-grow: 1;
    flex-shrink: 0;
}

@media screen and (max-width: 1200px) {
    .resume-row {
        flex-wrap: wrap;
    }
}